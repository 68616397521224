import { Component, OnInit, HostListener } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {

  isShown = false
  constructor() { }

  ngOnInit() {
  }


  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    if (window.pageYOffset <= 300) {
      this.isShown = false
    } else {
      this.isShown = true
    }
  }

  scrollToTop() {    
    $("html, body").animate({scrollTop : 0},700);
		return false;
  }
}
