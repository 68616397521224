import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlaceType } from '../place-type.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { config } from 'src/app/services/config';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  items: Array<PlaceType> = []
  searchChanged: Subject<string> = new Subject<string>();
  lang = config.DEFAULT_LANG
  config: any = {
    limit: 10,
    offset: 0,
  }

  constructor(
    private placeTypeService: PlaceTypeService,
    private _data: DataService,
    private _swl: SwalService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.getItems()

    this.searchChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.config.keywords = data
        this.items = []
        this.getItems()
      });
  }

  ngOnDestroy(): void {
    this.searchChanged.unsubscribe()
  }

  onSearch(text: string) {
    this.searchChanged.next(text);
  }

  onEdit(item: PlaceType) {
    this._data.setData(item)
    this._router.navigate(['setting/place-type/update']);
  }
  
  onDelete(item) {
    this._swl.swlOnDelete().then(() => {
      this.deleteItem(item)
    })
  }

  getItems(): void {
    let filters = {
      limit: this.config.limit,
      offset: this.config.offset,
      keywords: this.config.keywords || '',
      lang: this.lang,
      with_object: true
    }
    this.placeTypeService.getPlaceTypes(filters).then((results: Array<PlaceType>) => {
      this.items = results
    })
  }

  deleteItem(item: PlaceType): void {
    this.placeTypeService.deletePlaceType(item).then((result) => {
      this._swl.swlOnSucess()
      this.getItems()
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
