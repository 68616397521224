import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from "../helpers";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  globalBodyClass = 'm-page--wide m-header--fixed m-header--fixed-mobile m-footer--push m-aside--offcanvas-default m-scroll-top--shown';

  constructor(private _router: Router) { }

  ngOnInit() {
    Helpers.bodyClass(this.globalBodyClass);
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);
      }
    });
  }

  

}
