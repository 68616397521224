import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PromotionRoutingModule } from './promotion-routing.module';
import { PromotionComponent } from './promotion.component';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { FormComponent } from './form/form.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSummernoteModule } from 'ngx-summernote';

@NgModule({
  declarations: [PromotionComponent, ListComponent, CreateComponent, UpdateComponent, FormComponent],
  imports: [
    CommonModule,
    PromotionRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxSummernoteModule 
  ]
})
export class PromotionModule { }
