import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { StoreComponent } from './store.component';
import { FormComponent } from './form/form.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { ListComponent } from './list/list.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LayoutModule } from 'src/app/layout/layout.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [StoreComponent, FormComponent, CreateComponent, UpdateComponent, ListComponent],
  imports: [
    CommonModule,
    StoreRoutingModule,
    InfiniteScrollModule,
    LayoutModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class StoreModule { }
