import { Component, OnInit } from '@angular/core';
import { ScriptLoaderService } from '../services/script-loader.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import * as $ from "jquery";

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  // constructor(private _script: ScriptLoaderService, private _router: Router) { }
  constructor(
    private _router: Router,
    private _script: ScriptLoaderService
    ) { }

  ngOnInit() {
    // this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js'], true)
    //   .then(result => {
    //     Helpers.setLoading(false);
    //     // optional js to be loaded once
    //     this._script.loadScripts('head', ['assets/vendors/custom/fullcalendar/fullcalendar.bundle.js'], true);
    //   });
    // this._router.events.subscribe((route) => {
    //   if (route instanceof NavigationStart) {
    //     (<any>mLayout).closeMobileAsideMenuOffcanvas();
    //     (<any>mLayout).closeMobileHorMenuOffcanvas();
    //     (<any>mApp).scrollTop();
    //     Helpers.setLoading(true);
    //     // hide visible popover
    //     (<any>$('[data-toggle="m-popover"]')).popover('hide');
    //   }
    //   if (route instanceof NavigationEnd) {
    //     // init required js
    //     (<any>mApp).init();
    //     (<any>mUtil).init();
    //     Helpers.setLoading(false);
    //     // content m-wrapper animation
    //     let animation = 'm-animate-fade-in-up';
    //     $('.m-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
    //       $('.m-wrapper').removeClass(animation);
    //     }).removeClass(animation).addClass(animation);
    //   }
    // });
  }

}
