import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService
  ) {}

  item: any;

  ngOnInit() {
    this.item = this._data.getData();

    if (!this.item) {
      this._router.navigate(['/setting/store']);
      return;
    }

    // this.getList(this.item)
  }

  onSubmit(value) {
    console.log(value);

    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value);
    });
  }

  updateItem(item) {
    item.id = this.item.id;
    this.storeService.putStore(item).then(
      (result: any) => {
        if (item.image) {
          result.image = item.image;
          this.storeService.updateStoreImage(result).then(result => {
            this._swl.swlOnSucess();
            this._router.navigate(['/setting/store']);
          });
        } else {
          this._swl.swlOnSucess();
          this._router.navigate(['/setting/store']);
        }
      },
      errors => {
        this._swl.swlOnError();
      }
    );
  }
}
