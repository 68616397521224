import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Language, config } from 'src/app/services/config';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input()
  set mode(value: string) {
    this.titleHeader = value
    if (value === 'update') {
      this.isUpdate = true
    }else {
    
    }
  }
  @Input()
  set data(value) {
    if (!this.form) {
      this.createGroup()
    }
    this.form.patchValue(value)
  }
 
  @Output() onSubmitFn = new EventEmitter

  form: FormGroup
  submitted: boolean = false
  currentStep: number = 1
  languages: Array<Language> = config.LANGUAGES
  roles: Array<any> = []

  placeTypes: Array<any> = []
  openingTime: {} = {}
  image: File
  imgURL: any

  isUpdate: boolean = false
  isChangePassword: boolean = false
  titleHeader: string

  constructor(
    private fb: FormBuilder,
    private _service: UserService,
  ) { }

  ngOnInit() {
    this.getRoles()
  }


  createGroup() {
    this.form = this.fb.group({
      username: [null, Validators.required],
      role_id: [null, Validators.required],
      
    });

    if(!this.isUpdate) {
      this.form.addControl('password',new FormControl('', Validators.required));
      this.form.addControl('password_confirmation',new FormControl('', Validators.required));
    }
    
  }


  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true

    if (this.form.invalid) {
      this.currentStep = 1
      return
    }


    this.onSubmitFn.emit(this.form.value)

  }

  getRoles() {
    this._service.getRoles().then((results: Array<any>) => {
      this.roles = results
    })
  }

  onChangePassword() {
    this.isChangePassword = !this.isChangePassword

    if (!this.isChangePassword) {
      this.form.removeControl('password_confirmation');
      this.form.removeControl('password');
      this.form.updateValueAndValidity();
    } else {
      this.form.addControl('password',new FormControl('', Validators.required));
      this.form.addControl('password_confirmation',new FormControl('', Validators.required));
    }
  }


}
