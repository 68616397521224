import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/news"
  }

  getList(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  get(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/" + params.id, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  post(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var key in params) {
        if (typeof (params[key]) === 'object' && !key.includes('image')) {
          for (var subKey in params[key]) {
            formData.append('news[' + key + '][' + subKey + ']', params[key][subKey]);
          }
        } else {
          formData.append('news[' + key + ']', params[key]);
        }
      }
      this.http.post<any>(this.apiUrl, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  put(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var key in params) {
        if (typeof (params[key]) === 'object' && !key.includes('image')) {
          for (var subKey in params[key]) {
            formData.append('news[' + key + '][' + subKey + ']', params[key][subKey]);
          }
        } else {
          formData.append('news[' + key + ']', params[key]);
        }
      }
      this.http.put<any>(this.apiUrl + '/' + params.id, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  delete(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
