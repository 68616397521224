import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {

  user: any
  isMenuActive: boolean = false
  isSubmenuActive: boolean = false

  constructor(
    private authService: AuthService,
    private _swl: SwalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.authService.getSession().user
  }

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive
  }

  onLogout() {
    this._swl.swlOnLogout().then(() => {
      this.router.navigate(['/login']);
      this._swl.swlOnSucess()
    })
  }

  toggleSubmenu() {
    this.isSubmenuActive = !this.isSubmenuActive
  }

}
