import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './faq.component';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { FormComponent } from './form/form.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [FaqComponent, ListComponent, CreateComponent, UpdateComponent, FormComponent],
  imports: [
    CommonModule,
    FaqRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule, 
  ]
})
export class FaqModule { }
