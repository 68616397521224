import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, config } from 'src/app/services/config';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() data: any
  @Input() mode: string
  @Output() onSubmitFn = new EventEmitter

  form: FormGroup
  submitted: boolean = false
  currentStep: number = 1
  languages: Array<Language> = config.LANGUAGES

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.createGroup()
    if(this.data){
      this.form.patchValue(this.data)
    }
  }

  createGroup() {
    const group = new FormGroup({})
    this.languages.forEach(lang => {
      if (lang.require) {
        group.addControl(lang.key, this.fb.control('', Validators.required))
      } else {
        group.addControl(lang.key, this.fb.control(''))
      }
    })

    this.form = this.fb.group({
      name: group
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true
    if (this.form.invalid) {
      this.currentStep = 1
      return
    }

    this.onSubmitFn.emit(this.form.value)

  }

}
