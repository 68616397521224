import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, config } from 'src/app/services/config';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input()
  set data(value) {
    if (!this.form) {
      this.createGroup()
    }
    this.form.patchValue(value)
  }
  @Input() mode: string
  @Output() onSubmitFn = new EventEmitter

  form: FormGroup
  submitted: boolean = false
  currentStep: number = 1
  languages: Array<Language> = config.LANGUAGES

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    
  }

  createGroup() {
    this.form = this.fb.group({
      title: this.createGroupLang(),
      description: this.createGroupLang(),
      value: [null, Validators.required],
    });


  }

  createGroupLang() {
    const group = new FormGroup({})
    this.languages.forEach(lang => {
      if (lang.require) {
        group.addControl(lang.key, this.fb.control('', Validators.required))
      } else {
        group.addControl(lang.key, this.fb.control(''))
      }
    })

    return group
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true
    if (this.form.invalid) {
      this.currentStep = 1
      return
    }


    let value = this.form.value

    this.onSubmitFn.emit(value)

  }

}
