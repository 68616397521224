import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule , OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS  } from 'ng-pick-datetime-moment';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxSummernoteModule } from 'ngx-summernote';
import { LayoutModule } from '../layout/layout.module';



export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY',
  datePickerInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  timePickerInput: 'HH:mm',
};


@NgModule({
  declarations: [
    MainComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    // OwlDateTimeModule, 
    // OwlNativeDateTimeModule,
    // OwlMomentDateTimeModule,
    InfiniteScrollModule,
    DragDropModule,
    NgxSummernoteModule,
    LayoutModule
  ],
  providers: [
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    // { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS , useValue: { useUtc: true } },

  ],
})
export class MainModule { }
