import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlaceTypeRoutingModule } from './place-type-routing.module';
import { PlaceTypeComponent } from './place-type.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { FormComponent } from './form/form.component';

@NgModule({
  declarations: [PlaceTypeComponent, ListComponent, CreateComponent, UpdateComponent, FormComponent],
  imports: [
    CommonModule,
    PlaceTypeRoutingModule,
    LayoutModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule 
  ]
})
export class PlaceTypeModule { }
