import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-step-header-action',
  templateUrl: './step-header-action.component.html',
  styleUrls: ['./step-header-action.component.scss']
})
export class StepHeaderActionComponent implements OnInit {

  @Input() type: string;
  @Input() 
  set cancel(value :boolean) {
    this.isCancel = value
  };
  @Input() 
  set submit(value :boolean) {
    this.isSubmit = value
  };
  @Output() submitted = new EventEmitter<number>();

  title: string
  mClass: string
  isCancel: boolean = true
  isSubmit: boolean = true

  constructor(
    private _date: DataService
  ) { }

  ngOnInit() {
    const routeTitle = this._date.getRouteTitle();
    
    switch (this.type) {
      case 'create': {
        this.title = "Create " + routeTitle
        this.mClass = " m-badge--primary"
        break;
      }
      case 'update': {
        this.title = "Update " + routeTitle
        this.mClass = " m-badge--accent"
      }
    }
  }

  onSubmit() {
    this.submitted.emit()
  }

}
