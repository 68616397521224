import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  form: any;
  constructor(
    private storeService: StoreService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService
  ) {}

  ngOnInit() {}
  onSubmit(value) {
    this._swl.swlOnCreate().then(() => {
      this.createItem(value);
    });
  }

  createItem(item: any) {
    this.storeService.postStore(item).then(
      (result: any) => {
        if (item.image) {
          result.image = item.image;
          this.storeService.updateStoreImage(result).then(result => {
            this._swl.swlOnSucess();
            this._router.navigate(['/setting/store']);
          });
        } else {
          this._swl.swlOnSucess();
          this._router.navigate(['/setting/store']);
        }
      },
      errors => {
        this._swl.swlOnError();
      }
    );
  }
}
