import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TestimonialService } from 'src/app/services/testimonial.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  item$ = new BehaviorSubject<any>({})

  constructor(
    private _service: TestimonialService,
    private _swl: SwalService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  onSubmit(value) {
    this._swl.swlOnCreate().then(() => {
      this.createItem(value)
    })
  }

  createItem(item) {
    this._service.post(item).then((result: any) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/testimonial']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
