import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { StoreGroupService } from 'src/app/services/store-group.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  storeGroup

  constructor(
    private storeGroupService: StoreGroupService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService, ) { }

  ngOnInit() {
    this.storeGroup = this._data.getData()
    if (!this.storeGroup) {
      this._router.navigate(['/setting/place-type']);
      return
    }
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updatePlaceType(value)
    })
  }

  updatePlaceType(item) {
    item.id = this.storeGroup.id
    this.storeGroupService.putStoreGroup(item).then((result) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/store-group']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
