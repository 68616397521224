import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/services/swal.service';
import { BehaviorSubject } from 'rxjs';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  item$ = new BehaviorSubject<any>('');

  constructor(private _service: BannerService, private _swl: SwalService) {}

  ngOnInit() {
    this.getItem();
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value);
    });
  }

  updateItem(item) {
    item.id = this.item$.getValue().id;
    this._service.putBanner(item).then(
      (result: any) => {
        if (item.image) {
          result.image = item.image;
          this._service.updateBannerImage(result).then(result => {
            this._swl.swlOnSucess();
          });
        } else {
          this._swl.swlOnSucess();
        }
      },
      errors => {
        this._swl.swlOnError();
      }
    );
  }

  getItem() {
    let filters = {
      with_object: true
    };
    this._service.getBanners(filters).then((result: any) => {
      this.item$.next(result);
    });
  }
}
