import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlaceRoutingModule } from './place-routing.module';
import { PlaceComponent } from './place.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormComponent } from './form/form.component';

@NgModule({
  declarations: [PlaceComponent, ListComponent, CreateComponent, UpdateComponent, FormComponent],
  imports: [
    CommonModule,
    PlaceRoutingModule,
    LayoutModule,
    NgSelectModule,
    FormsModule,
    InfiniteScrollModule,
    ReactiveFormsModule 
  ]
})
export class PlaceModule { }
