import { Component, OnInit } from '@angular/core';
import { PlaceService } from 'src/app/services/place.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { StoreGroupService } from 'src/app/services/store-group.service';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  form: any
  constructor(private storeGroupService: StoreGroupService,
    private _swl: SwalService,
    private _router: Router) { }

  ngOnInit() {
  }
  onSubmit(value) {
    this._swl.swlOnCreate().then(() => {
      this.createItem(value)
    })
  }

  createItem(item: any) {
    this.storeGroupService.postStoreGroup(item).then((result: any) => {
      if (item.image) {
        result.image = item.image
        this.storeGroupService.putStoreGroup(result).then((result) => {
          this._swl.swlOnSucess()
          this._router.navigate(['/setting/store-group']);
        })
      } else {
        this._swl.swlOnSucess()
        this._router.navigate(['/setting/store-group']);
      }
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
