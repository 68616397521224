export const config = Object.freeze({
  BASE_API_URL: 'https://api.ttexchange.com',
  // BASE_API_URL: 'http://localhost:3001',
  DEFAULT_IMAGE: '/assets/images/image-placeholder.png',
  DEFAULT_LANG: 'en_us',
  LANGUAGES: [
    { id: 1, key: 'en_us', require: true, name: 'English' },
    { id: 2, key: 'th', require: false, name: 'Thai' },
    { id: 3, key: 'cn', require: false, name: 'China' },
    { id: 4, key: 'ru', require: false, name: 'Russia' }
  ],
  WEEKS: [
    { name: 'Mon', key: '1', active: false },
    { name: 'Tue', key: '2', active: false },
    { name: 'Web', key: '3', active: false },
    { name: 'Thu', key: '4', active: false },
    { name: 'Fri', key: '5', active: false },
    { name: 'Sat', key: '6', active: false },
    { name: 'Sun', key: '0', active: false }
  ]
});

export interface Language {
  id: number;
  key: string;
  name: string;
  require: boolean;
}

export interface Week {
  key: string;
  name: string;
  active: boolean;
}
