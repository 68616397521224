import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/banners"
  }

  getBanners(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  postBanner(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { banner: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  putBanner(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/' + params.id, { banner: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  updateBannerImage(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('banner[image]', params.image);
      this.http.put<any>(this.apiUrl + '/' + params.id, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  deleteBanner(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
