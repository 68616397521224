import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { BehaviorSubject } from 'rxjs';
import { PromotionService } from 'src/app/services/promotion.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  item$ = new BehaviorSubject<any>({})

  constructor(
    private _service: PromotionService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService,
  ) { }

  ngOnInit() {
    const data = this._data.getData()

    if (!data) {
      this._router.navigate(['/setting/promotion']);
      return
    }

    this.getItem(data)
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value)
    })
  }

  updateItem(item) {
    item.id = this.item$.getValue().id
    this._service.put(item).then((result) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/promotion']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

  getItem(item) {
    let filters = {
      with_object: true,
      id: item.id
    }
    this._service.get(filters).then((result) => {
      this.item$.next(result)
    })
  }

}
