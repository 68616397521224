import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { ListComponent } from './list/list.component';
import { FormComponent } from './form/form.component';
import { UpdateComponent } from './update/update.component';
import { CreateComponent } from './create/create.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSummernoteModule } from 'ngx-summernote';

@NgModule({
  declarations: [NewsComponent, ListComponent, FormComponent, UpdateComponent, CreateComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxSummernoteModule 
  ]
})
export class NewsModule { }
