import { Injectable } from '@angular/core';
import { config } from './config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SafeBoxService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/safe_box_branches"
  }

  getList(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  get(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/" + params.id, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  updateImage(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('safe_box_branch[image]', params.image);
      this.http.put<any>(this.apiUrl + '/' + params.id, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  post(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { safe_box_branch: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  put(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/' + params.id, { safe_box_branch: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  delete(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
