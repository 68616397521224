import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SafeBoxPackageService } from 'src/app/services/safe-box-package.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-branch-package',
  templateUrl: './branch-package.component.html',
  styleUrls: ['./branch-package.component.scss']
})
export class BranchPackageComponent implements OnInit {

  @Input() 
  set data(values) {
    this.items = values
  }
  @Output() changePackage = new EventEmitter

  items: Array<any> = []
  options: Array<any> = []
  safe_box_package_id: number = null

  constructor(
    private _service: SafeBoxPackageService
  ) { }

  ngOnInit() {
    this.getOptionList()
  }

  getOptionList() {
    this._service.getList().then((results: Array<any>) => {
      this.options = results
      this.options.forEach((option) => {
        option.title = option.size_name + " : " + option.size_dimension
      })
    })
  }

  addBranchPackage(item) {
    if (item) {
      let branchPackage = this.items.find((branchPackage) => { return branchPackage.safe_box_branch_package_id === item.id })
      if (branchPackage) {
        return
      }

      this.items.push({ safe_box_branch_package: item, safe_box_branch_package_id: item.id, period: {} })

      this.changePackage.emit(this.items)
    }
  }

  onDeleteBranchPackage(index: number) {
    this.items.splice(index, 1)
    this.changePackage.emit(this.items)
  }

  dropped(event: CdkDragDrop<string[]>) {
    this.changePackage.emit(this.items)

    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }


}
