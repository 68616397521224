import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, config } from 'src/app/services/config';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() data: any
  @Input() mode: string
  @Output() onSubmitFn = new EventEmitter

  form: FormGroup
  submitted: boolean = false
  currentStep: number = 1
  languages: Array<Language> = config.LANGUAGES

  placeTypes: Array<any> = []
  openingTime: {} = {}
  image: File
  imgURL: any

  constructor(
    private fb: FormBuilder,
    private placeTypeService: PlaceTypeService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.createGroup()
    this.getPlaceTypes()

    if (this.data) {
      this.openingTime = this.data.opening_time

      if (this.data.image_url) {
        this.imgURL = config.BASE_API_URL + this.data.image_url
      } else {
        this.imgURL = config.DEFAULT_IMAGE
      }

      this.form.patchValue(this.data)
    }
  }

  getPlaceTypes() {
    this.placeTypeService.getPlaceTypes().then((results: Array<any>) => {
      this.placeTypes = results
    })
  }

  createGroup() {
    this.form = this.fb.group({
      name: this.createGroupLang(),
      address: this.createGroupLang(),
      description: this.createGroupLang(),
      phone: ['', [Validators.required]],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      place_type_id: ['', Validators.required],
    });
  }

  createGroupLang() {
    const group = new FormGroup({})
    this.languages.forEach(lang => {
      if (lang.require) {
        group.addControl(lang.key, this.fb.control('', Validators.required))
      } else {
        group.addControl(lang.key, this.fb.control(''))
      }
    })

    return group
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true
    if (this.form.invalid) {
      this.currentStep = 1
      return
    }

    let value = this.form.value

    value.user_id = this.authService.getSession().user.id
    for (var key in this.openingTime) {
      delete this.openingTime[key].from_time_moment;
      delete this.openingTime[key].to_time_moment;
    }
    value.opening_time = this.openingTime
    if (this.image) {
      value.image = this.image
    }

    this.onSubmitFn.emit(value)

  }

}
