import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeBoxBranchRoutingModule } from './safe-box-branch-routing.module';
import { SafeBoxBranchComponent } from './safe-box-branch.component';
import { ListComponent } from './list/list.component';
import { UpdateComponent } from './update/update.component';
import { FormComponent } from './form/form.component';
import { CreateComponent } from './create/create.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [SafeBoxBranchComponent, ListComponent, UpdateComponent, FormComponent, CreateComponent],
  imports: [
    CommonModule,
    SafeBoxBranchRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgSelectModule,
  ]
})
export class SafeBoxBranchModule { }
