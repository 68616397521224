import { Injectable } from '@angular/core';

import { config } from './config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlaceTypeService {

  apiUrl: any
  options = {}

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/place_types"
  }

  getPlaceTypes(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl,{ params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  getPlaceType(id: number, params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + '/'+ id,{ params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  postPlaceType(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { place_type: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  putPlaceType(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/'+ params.id, { place_type: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  deletePlaceType(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/'+ params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
