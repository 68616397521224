import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingComponent } from './setting.component';
import { PlaceTypeModule } from './place-type/place-type.module';
import { PlaceModule } from './place/place.module';
import { PlaceCategoryModule } from './place-category/place-category.module';
import { AboutModule } from './about/about.module';
import { BannerModule } from './banner/banner.module';
import { NewsModule } from './news/news.module';
import { PromotionModule } from './promotion/promotion.module';
import { FactModule } from './fact/fact.module';
import { FaqModule } from './faq/faq.module';
import { SafeBoxPackageModule } from './safe-box-package/safe-box-package.module';
import { UserModule } from './user/user.module';
import { StoreGroupModule } from './store-group/store-group.module';
import { StoreModule } from './store/store.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { TestimonialModule } from './testimonial/testimonial.module';
import { SafeBoxBranchModule } from './safe-box-branch/safe-box-branch.module';

const routes: Routes = [
  {
    path: '', component: SettingComponent,
    children: [
      { path: '', redirectTo: "about", pathMatch: "full" },
      {
        path: 'place-type',
        // loadChildren: ".place-type/place-type.module#PlaceTypeModule"
        // loadChildren: "src/app/main/setting/place-type/place-type.module#PlaceTypeModule"
        loadChildren: () => PlaceTypeModule
      },
      {
        path: 'place',
        // loadChildren: "./place/place.module#PlaceModule"
        loadChildren: () => PlaceModule
      },
      {
        path: 'place-category',
        loadChildren: () => PlaceCategoryModule
        // loadChildren: "./place-category/place-category.module#PlaceCategoryModule"
      },
      {
        path: 'banner',
        // loadChildren: "./banner/banner.module#BannerModule"
        loadChildren: () => BannerModule
      },
      {
        path: 'news',
        // loadChildren: "./news/news.module#NewsModule"
        loadChildren: () => NewsModule
      },
      {
        path: 'promotion',
        // loadChildren: "./promotion/promotion.module#PromotionModule"
        loadChildren: () => PromotionModule
      },
      {
        path: 'fact',
        // loadChildren: "./fact/fact.module#FactModule"
        loadChildren: () => FactModule
      },
      {
        path: 'faq',
        // loadChildren: "./faq/faq.module#FaqModule"
        loadChildren: () => FaqModule
      },
      {
        path: 'safe-box-package',
        // loadChildren: "./safe-box-package/safe-box-package.module#SafeBoxPackageModule"
        loadChildren: () => SafeBoxPackageModule
      },
      {
        path: 'safe-box-branch',
        // loadChildren: "./safe-box-branch/safe-box-branch.module#SafeBoxBranchModule"
        loadChildren: () => SafeBoxBranchModule
      },
      {
        path: 'testimonial',
        // loadChildren: "./testimonial//testimonial.module#TestimonialModule"
        loadChildren: () => TestimonialModule
      },
      {
        path: 'landing-page',
        // loadChildren: "./landing-page/landing-page.module#LandingPageModule"
        loadChildren: () => LandingPageModule
      },
      {
        path: 'about',
        loadChildren: () => AboutModule
      },

      {
        path: 'user',
        // loadChildren: "./user/user.module#UserModule"
        loadChildren: () => UserModule
      },
      {
        path: 'store-group',
        // loadChildren: "./store-group/store-group.module#StoreGroupModule"
        loadChildren: () => StoreGroupModule
      },
      {
        path: 'store',
        // loadChildren: "./store/store.module#StoreModule"
        loadChildren: () => StoreModule
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
