import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config, Language } from 'src/app/services/config';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit {

  // @Input() title: string
  @Input() lang: string
  @Input() createRouter: string
  @Output() changeLang = new EventEmitter
  @Output() search = new EventEmitter

  languages: Array<Language> = config.LANGUAGES
  title: String

  keywords: any = null

  constructor(
    private _data: DataService
  ) { }

  ngOnInit() {
    this.title = "Table " + this._data.getRouteTitle()
  }

  onChangeLang(value: Language) {
    this.changeLang.emit(value.key)
  }

  onSearch(value: string) {
    this.search.emit(value)
  }

}
