import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MainComponent } from './main.component';
import { AuthGuard } from '../_guards/auth.guard';
import { SettingModule } from './setting/setting.module';

const routes: Routes = [
  {
    path: '', component: MainComponent, 
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: "setting", pathMatch: "full"},
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      // },
      {
        path: 'setting',
        loadChildren: () => SettingModule
      },
      // {
      //   path: 'setting',
      //   component: SettingsComponent,
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: '/setting/user',
      //       pathMatch: 'full'
      //     },
      //     {
      //       path: 'user',
      //       component: UserComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'User'
      //       }
      //     },
      //     {
      //       path: 'place',
      //       component: PlaceComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Place'
      //       }
      //     },
      //     {
      //       path: 'place-type',
      //       component: PlaceTypeComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Place Type'
      //       }
      //     },
      //     {
      //       path: 'place-category',
      //       component: PlaceCategoryComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Place Category'
      //       }
      //     },
      //     {
      //       path: 'store-group',
      //       component: StoreGroupComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Store Group'
      //       }
      //     },
      //     {
      //       path: 'store',
      //       component: StoreComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Store'
      //       }
      //     },
      //     {
      //       path: 'promotion',
      //       component: PromotionComponent,
      //       data : {
      //         header : 'Setting',
      //         title: 'Promotion'
      //       }
      //     },
      //     {
      //       path: 'news',
      //       component: NewsComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'News'
      //       }
      //     },
      //     {
      //       path: 'safe-box',
      //       component: SafeBoxComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'Safe Box Branch'
      //       }
      //     },
      //     {
      //       path: 'safe-box-package',
      //       component: SafeBoxPackageComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'Safe Box Package'
      //       }
      //     },
      //     {
      //       path: 'banner',
      //       component: BannerComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'Banner'
      //       }
      //     },
      //     {
      //       path: 'faq',
      //       component: FaqComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'FAQ'
      //       }
      //     },
      //     {
      //       path: 'about',
      //       component: AboutComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'About Us'
      //       }
      //     },
      //     {
      //       path: 'fact',
      //       component: FactComponent, 
      //       data : {
      //         header : 'Setting',
      //         title: 'Fact'
      //       }
      //     },
      //   ]
      // },
    ]
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
