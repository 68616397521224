import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreGroupRoutingModule } from './store-group-routing.module';
import { StoreGroupComponent } from './store-group.component';
import { ListComponent } from './list/list.component';
import { UpdateComponent } from './update/update.component';
import { CreateComponent } from './create/create.component';
import { FormComponent } from './form/form.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LayoutModule } from 'src/app/layout/layout.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [StoreGroupComponent, ListComponent, UpdateComponent, CreateComponent, FormComponent],
  imports: [
    CommonModule,
    StoreGroupRoutingModule,
    InfiniteScrollModule,
    LayoutModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class StoreGroupModule { }
