import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { SwalService } from 'src/app/services/swal.service';
import { PlaceType } from '../place-type.model';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  placeType: PlaceType

  constructor(
    private placeTypeService: PlaceTypeService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService,
  ) { }

  ngOnInit() {
    this.placeType = this._data.getData()
    if (!this.placeType) {
      this._router.navigate(['/setting/place-type']);
      return
    }
  }

  onSubmit(value) {
    value.id = this.placeType.id
    this._swl.swlOnUpdate().then(() => {
      this.updatePlaceType(value)
    })
  }

  updatePlaceType(item) {
    this.placeTypeService.putPlaceType(item).then((result) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/place-type']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
