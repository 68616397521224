import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class PlaceCategoryService {

  apiUrl: any
  options = {}

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/place_categories"
  }

  getList(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl,{ params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  post(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { place_category: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  put(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/'+ params.id, { place_category: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  delete(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/'+ params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
