import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data: any
  private routeSubject$ = new BehaviorSubject("");

  setData(item: any) {
    sessionStorage.setItem('data', JSON.stringify(item))
    this.data = item
  }

  getData() {
    if (!this.data) {
      this.data = JSON.parse(sessionStorage.getItem('data'))
    }
    return this.data
  }

  getRouteTitle() {
    return this.routeSubject$.getValue()
  }

  setRouteTitle(title: string) {
    this.routeSubject$.next(title)
  }



  constructor() { }
}
