import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, config } from 'src/app/services/config';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  set data(value) {
    if (!this.form) {
      this.createGroup();
    }
    this.initForm(value);
    this.form.patchValue(value);
  }
  @Input() mode: string;
  @Output() onSubmitFn = new EventEmitter();

  form: FormGroup;
  submitted: boolean = false;
  currentStep: number = 1;
  languages: Array<Language> = config.LANGUAGES;

  openingTime: {} = {};
  image: File;
  imgURL: any;

  summernoteOptions = {
    placeholder: '',
    tabsize: 2,
    height: 400,
    uploadImagePath: '',
    toolbar: [
      // [groupName, [list of button]]
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit() {}

  initForm(data) {
    if (data) {
      this.openingTime = data.opening_time;

      if (data.image_url) {
        this.imgURL = config.BASE_API_URL + data.image_url;
      } else {
        this.imgURL = config.DEFAULT_IMAGE;
      }
    }
  }

  createGroup() {
    this.form = this.fb.group({
      title: this.createGroupLang(),
      short_description: this.createGroupLang(),
      description: this.createGroupLang(),
      post_date: [null, Validators.required],
      is_active: [],
      is_new: []
    });
  }

  createGroupLang() {
    const group = new FormGroup({});
    this.languages.forEach(lang => {
      if (lang.require) {
        group.addControl(lang.key, this.fb.control('', Validators.required));
      } else {
        group.addControl(lang.key, this.fb.control(''));
      }
    });

    return group;
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.currentStep = 1;
      return;
    }

    let value = this.form.value;
    var datePipe = new DatePipe('en-US');
    value.post_date = datePipe.transform(value.post_date, 'dd/MM/yyyy');
    value.user_id = this.authService.getSession().user.id;
    if (this.image) {
      value.image = this.image;
    }

    this.onSubmitFn.emit(value);
  }
}
