import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BannerRoutingModule } from './banner-routing.module';
import { BannerComponent } from './banner.component';
import { UpdateComponent } from './update/update.component';
import { FormComponent } from './form/form.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BannerComponent, UpdateComponent, FormComponent],
  imports: [
    CommonModule,
    BannerRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule 
  ]
})
export class BannerModule { }
