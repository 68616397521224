import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-package-period',
  templateUrl: './package-period.component.html',
  styleUrls: ['./package-period.component.scss']
})
export class PackagePeriodComponent implements OnInit {

  @Input()
  set initData(values) {
    this.packages = values

    if (this.packages) {
      this.packages.forEach((branchPackage) => {
        branchPackage.period = {}
        branchPackage.periods.forEach((periodPackage) => {
          branchPackage.period[periodPackage.month] = periodPackage.price
          this.addPeriodPackage(periodPackage.month)
        })
      })
    }

  }

  @Input()
  set data(values) {
    if (values) {
      this.packages = values
    }
  }

  @Output() dataChange = new EventEmitter
  @Output() periodPackagesChange = new EventEmitter

  package: any = {}
  packages = []
  periodPackages = []

  constructor() { }

  ngOnInit() {
  }

  onDataChange() {
    this.dataChange.emit(this.packages)
  }

  validPagkage(key: string, max: number, min: number) {
    if (this.package[key] > max) {
      this.package[key] = max
    }
    else if (this.package[key] < min) {
      this.package[key] = min
    }
  }

  onAddPackage() {
    let month = (this.package.month || 0) + ((this.package.year || 0) * 12)
    this.addPeriodPackage(month)
    this.package = {}
  }

  addPeriodPackage(month: number) {
    let oldPeriod = this.periodPackages.find((periodPackage) => { return periodPackage.month === month })

    if (month > 0 && !oldPeriod) {
      const result = { month: month, result: this.showPackagePeriod(month) }
      this.periodPackages.push(result)
      this.periodPackages.sort((a, b) => {
        return a.month - b.month
      })

      this.periodPackagesChange.emit(this.periodPackages)
    }
  }

  showPackagePeriod(totalMonth: number) {
    let month = totalMonth % 12
    let year = parseInt((totalMonth / 12).toString())
    let result = ""
    if (year != 0) {
      result += year + " YEAR "
    }
    if (month != 0) {
      result += month + " MONTHS"
    }
    return result
  }


}
