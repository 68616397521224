import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Week, config } from 'src/app/services/config';
import * as moment from 'moment';

@Component({
  selector: 'app-set-time-opening',
  templateUrl: './set-time-opening.component.html',
  styleUrls: ['./set-time-opening.component.scss']
})
export class SetTimeOpeningComponent implements OnInit {

  @Input() openingTime;
  @Output() changeTime = new EventEmitter<any>();

  weeks: Array<Week> = config.WEEKS
  config: any = {}
  opening_time = {}

  constructor() { }

  ngOnInit() {
    this.opening_time = this.openingTime || {}
    if (this.opening_time['0']) {
      this.setUpdateWeek()      
    } else {
      this.initWeek()
    }

  }

  onChangeTime(event, wKey, key) {
    this.opening_time[wKey][key] = moment(event).format("HH:mm")

    if (this.opening_time[wKey].from_time && this.opening_time[wKey].to_time) {
      this.opening_time[wKey].status = "open"
    }

    this.changeTime.emit(this.opening_time)
  }

  onSetTime() {
    let weekActives = this.weeks.filter(week => week.active === true)
    weekActives.forEach((weekActive) => {
      this.opening_time[weekActive.key].from_time = moment(this.config.from_time).format("HH:mm")
      this.opening_time[weekActive.key].from_time_moment = this.config.from_time
      this.opening_time[weekActive.key].to_time = moment(this.config.to_time).format("HH:mm")
      this.opening_time[weekActive.key].to_time_moment = this.config.to_time

      if (this.opening_time[weekActive.key].from_time && this.opening_time[weekActive.key].to_time) {
        this.opening_time[weekActive.key].status = "open"
      }

    })

    // Reset default config
    this.config.from_time = null
    this.config.to_time = null
    this.weeks.forEach((week) => {
      week.active = false
    })

    this.changeTime.emit(this.opening_time)
  }

  initWeek() {
    this.weeks.forEach((week) => {
      this.opening_time[week.key] = {
        status: 'close'
      }
    })

    this.changeTime.emit(this.opening_time)
  }

  setUpdateWeek() {
    this.weeks.forEach((week) => {
      if (this.opening_time[week.key]) {
        if (this.opening_time[week.key].from_time) {
          this.opening_time[week.key].from_time_moment = moment(this.opening_time[week.key].from_time, "HH:mm")
        }
        if (this.opening_time[week.key].to_time) {
          this.opening_time[week.key].to_time_moment = moment(this.opening_time[week.key].to_time, "HH:mm")
        }
      } else {
        this.opening_time[week.key] = {}
      }

    })
  }


}
