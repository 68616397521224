import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PlaceService } from 'src/app/services/place.service';

@Component({
  selector: 'app-landing-place',
  templateUrl: './landing-place.component.html',
  styleUrls: ['./landing-place.component.scss']
})
export class LandingPlaceComponent implements OnInit {

  @Input()
  set data(value) {
    if (value) {
      this.items = value.landing_places
      this.placeTypeID = value.place_type_id
      this.getOptionItems()

      if(!value.place_type_id) {
        this.limit = 4
      }
    }

  }
  @Output() dataChange = new EventEmitter

  options: Array<any> = []
  items: Array<any> = []
  placeTypeID: number
  place_id: number

  limit: number = 3


  constructor(
    private _service: PlaceService
  ) { }

  ngOnInit() {
    
  }

  getOptionItems() {
    let filters = {
      place_type_id: this.placeTypeID 
    }
    filters = this.placeTypeID ? filters : null
    this._service.getPlaces(filters).then((results: Array<any>) => {
      this.options = results
      this.options.forEach((option) => {
        option.place_name = option.name
      })
    })
  }

  onAddItem(item) {
    let data = Object.assign({}, item)
    if (!data.id) {
      return
    }    
    data.place_id = data.id
    const dupItem = this.items.find((obj) => { return obj.place_id === data.place_id })
    if (dupItem) {
      return
    }
    if (this.items.length == this.limit) {
      return
    }
    this.items.push(data)

    this.dataChange.emit(this.items)
  }

  onDeleteItem(index: number) {
    this.items.splice(index, 1)

    this.dataChange.emit(this.items)
  }

  onChangeShow(data, value: boolean) {
    if (value) {
      this.items.forEach((item) => {
        item.is_show = false
      })
      data.is_show = true

      this.dataChange.emit(this.items)
    }
  }

}
