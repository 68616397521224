import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup
  submitted = false
  loading = false
  errors: string
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
    })
    this.authService.logout()

    // get return url from route parameters or default to '/'
    this.returnUrl = '/setting';
  }

  // convenience getter for easy access to form fields
  get f() { return this.authForm.controls; }

  onSignIn() {
    this.submitted = true
    this.errors = ""
    if (this.authForm.invalid) {
      return
    }

    this.loading = true

    this.authService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.errors) {
            this.loading = false;
            this.errors = data.errors
            return
          }
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.errors = error;
          this.loading = false;
        });

  }

}
