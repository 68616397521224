import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Language, config } from 'src/app/services/config';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-step-language',
  templateUrl: './step-language.component.html',
  styleUrls: ['./step-language.component.scss']
})
export class StepLanguageComponent implements OnInit {

  @Input() currentStep: number;
  @Input()
  set scrollTop(value: boolean){
    this.isScrollTop = value
  }
  @Output() chagngeCurrentStep = new EventEmitter<number>();

  languages: Array<Language> = config.LANGUAGES
  dones: Array<number> = []

  isScrollTop: boolean = true

  constructor(
    private _main: MainService
  ) { }

  ngOnInit() {
  }

  isDone(id: number) {
    return this.dones.includes(id)
  }

  onChnageStep(index: number) {
    this.currentStep = index
    this.chagngeCurrentStep.emit(this.currentStep);

    if(this.isScrollTop) {
      this._main.scrollToTop()
    }
  }

}
