import { Component, OnInit } from '@angular/core';
import { Helpers } from 'src/app/helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  globalBodyClass = 'm-page--wide m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default m-scroll-top--shown';

  constructor(private _router: Router) { }

  ngOnInit() {
    
    Helpers.bodyClass(this.globalBodyClass);
    	this._router.events.subscribe((route) => {
      Helpers.bodyClass(this.globalBodyClass);
		});
  }

}
