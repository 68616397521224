import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {
  breadcrumb :any

  constructor(
    private route: ActivatedRoute,
    private _date: DataService
    ) { }

  ngOnInit() {    
    this.breadcrumb = this.route.snapshot.data
    this._date.setRouteTitle(this.breadcrumb.title)
  }

}
