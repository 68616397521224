import { Injectable } from '@angular/core';
import { config } from './config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/places"
  }

  getPlaces(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  getPlace(id: number, params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + '/' + id, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  postPlace(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { place: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  putPlace(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/' + params.id, { place: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  updatePlaceImage(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('place[image]', params.image);
      this.http.put<any>(this.apiUrl + '/' + params.id, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  deletePlace(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
