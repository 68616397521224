import { Component, OnInit, forwardRef, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input()
  set data(value) {
    this.date = value
  }

  @Output() change = new EventEmitter

  date: any

  constructor() { }

  ngOnInit() {

  }

  onChange(value) {
    this.change.emit(value)
  }



}
