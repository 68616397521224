import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/stores"
  }

  getStores(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  postStore(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { store: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  putStore(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/' + params.id, { store: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  updateStoreImage(params: any) {
    let promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('store[image]', params.image);
      this.http.put<any>(this.apiUrl + '/' + params.id, formData).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  deleteStore(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  getBrands(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get("https://api.software.ttexchange.com/branches", { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }
}
