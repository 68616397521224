import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { config } from 'src/app/services/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService  {

  private apiUrl: any

  constructor(private http: HttpClient) { this.apiUrl = config.BASE_API_URL }

  getSession () {
    return JSON.parse(sessionStorage.getItem('currentUser'))
  }

  login (username: string, password: string) {
    return this.http.post<any>(`${this.apiUrl}/sessions`, { username: username, password: password })
    .pipe(map(data => {
        // login successful if there's a user in the response
        if (data.user) {
            // store user details and basic auth credentials in local storage 
            // to keep user logged in between page refreshes
            data.authdata = window.btoa(username + ':' + password);
            sessionStorage.setItem('currentUser', JSON.stringify(data));
        }
        return data;
    })); 
  }

  logout () {
    sessionStorage.removeItem('currentUser');
  }

  
}
