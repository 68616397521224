import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class StoreGroupService {

  apiUrl: any
  options = {}

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/store_groups"
  }

  getStoreGroups(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl,{ params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  postStoreGroup(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { store_group: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  putStoreGroup(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/'+ params.id, { store_group: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  deleteStoreGroup(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/'+ params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return resolve(error)
      })
    })
    return promise
  }
}
