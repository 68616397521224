import { Component, OnInit } from '@angular/core';
import { PlaceCategoryService } from 'src/app/services/place-category.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  item: any

  constructor(
    private _service: PlaceCategoryService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService,
  ) { }

  ngOnInit() {
    this.item = this._data.getData()

    if (!this.item) {
      this._router.navigate(['/setting/place-category']);
      return
    }
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value)
    })
  }

  updateItem(item) {
    item.id = this.item.id
    this._service.put(item).then((result) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/place-category']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
