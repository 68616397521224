import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class SafeBoxPackageService {

  apiUrl: string

  constructor(private http: HttpClient) {
    this.apiUrl = config.BASE_API_URL + "/safe_box_branch_packages"
  }

  getList(params?: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiUrl, { params }).subscribe(result => {
        return resolve(result)
      })
    })
    return promise
  }

  post(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(this.apiUrl, { safe_box_branch_package: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  put(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/' + params.id , { safe_box_branch_package: params }).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }

  delete(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http.delete<any>(this.apiUrl + '/' + params.id).subscribe(result => {
        return resolve(result)
      }, (error) => {
        return reject(error)
      })
    })
    return promise
  }
}
