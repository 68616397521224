import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  swlOnLogout() {
    let promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to logout',
        type: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Sure',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          resolve()
        }
      })
    })
    return promise
  }

  swlOnCreate() {
    let promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to create this item',
        type: 'info',
        showCancelButton: true,
        // showLoaderOnConfirm: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          resolve()
        }
      })
    })
    return promise
  }

  swlOnUpdate() {
    let promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to update this item.',
        type: 'warning',
        showCancelButton: true,
        // showLoaderOnConfirm: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          resolve()
        }
      })
    })
    return promise
  }

  swlOnDelete() {
    let promise = new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this item.',
        type: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#f4516c',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          resolve()
        }
      })
    })
    return promise;

  }

  swlOnSucess() {
    Swal.fire({
      type: 'success',
      title: 'Success!',
      text: 'Your request has been succeeded.',
      showConfirmButton: false,
      timer: 1500
    })
  }

  swlOnError(message? :string, isComfirmButton?: boolean) {
    Swal.fire({
      type: 'error',
      title: 'Error!',
      text: message || 'Your request has some error.',
      showConfirmButton: isComfirmButton || false,
      timer: isComfirmButton ? null : 1500
    })
  }


}
