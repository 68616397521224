import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BannerComponent } from './banner.component';
import { UpdateComponent } from './update/update.component';

const routes: Routes = [
  {
    path: '', component: BannerComponent,
    data: {
      header: 'Setting',
      title: 'Banner'
    },
    children: [
      { path: '', redirectTo: "update", pathMatch: "full" },
      // {
      //   path: "list",
      //   component: ListComponent
      // },
      // {
      //   path: "create",
      //   component: CreateComponent
      // },
      {
        path: "update",
        component: UpdateComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BannerRoutingModule { }
