import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, config } from 'src/app/services/config';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input()
  set data(value) {
    if (!this.form) {
      this.createGroup()
    }
    if(value) {
      this.initPackage$.next(value.packages)
      this.form.patchValue(value)
    }
  }

  @Input() mode: string
  @Output() onSubmitFn = new EventEmitter

  form: FormGroup
  submitted: boolean = false
  currentStep: number = 1
  languages: Array<Language> = config.LANGUAGES

  stores: Array<any> = []

  package$: BehaviorSubject<any> = new BehaviorSubject<any>([])
  initPackage$: BehaviorSubject<any> = new BehaviorSubject<any>([])
  periodPackages = []

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private _storeService: StoreService
  ) { }

  ngOnInit() {
    this.getStores()
  }



  createGroup() {
    this.form = this.fb.group({
      note: this.createGroupLang(),
      store_id: [null, Validators.required],
    });
  }

  createGroupLang() {
    const group = new FormGroup({})
    this.languages.forEach(lang => {
      group.addControl(lang.key, this.fb.control(''))
    })

    return group
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true
    if (this.form.invalid) {
      this.currentStep = 1
      return
    }


    let value = this.form.value
    value.user_id = this.authService.getSession().user.id
    value.packages = this.package$.getValue()

    value.packages.forEach((branchPackage) => {
      branchPackage.periods = []
      this.periodPackages.forEach((periodPackage) => {
        let price = branchPackage.period[periodPackage.month]
        branchPackage.periods.push({ month: periodPackage.month, price: price })
      })
    })

    this.onSubmitFn.emit(value)

  }

  getStores() {
    this._storeService.getStores().then((results: Array<any>) => {
      this.stores = results
    })
  }

  changePackage(item) {
    this.package$.next(item)
  }

}
