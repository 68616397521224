import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubheaderComponent } from './subheader/subheader.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { RouterModule } from '@angular/router';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { StepLanguageComponent } from './step-language/step-language.component';
import { StepActionComponent } from './step-action/step-action.component';
import { StepHeaderActionComponent } from './step-header-action/step-header-action.component';
import { SetTimeOpeningComponent } from './set-time-opening/set-time-opening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { BranchPackageComponent } from './branch-package/branch-package.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PackagePeriodComponent } from './package-period/package-period.component';
import { LandingPlaceComponent } from './landing-place/landing-place.component';

export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY',
  datePickerInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  timePickerInput: 'HH:mm',
};


@NgModule({
  declarations: [
    SubheaderComponent,
    FooterComponent,
    ScrollTopComponent,
    HeaderNavComponent,
    AsideNavComponent,
    StepLanguageComponent,
    StepActionComponent,
    StepHeaderActionComponent,
    SetTimeOpeningComponent,
    ImagePickerComponent,
    HeaderSearchComponent,
    DatePickerComponent,
    BranchPackageComponent,
    PackagePeriodComponent,
    LandingPlaceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    NgSelectModule,
    ReactiveFormsModule,
    DragDropModule

  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
  exports: [
    DatePickerComponent,
    SubheaderComponent,
    FooterComponent,
    ScrollTopComponent,
    HeaderNavComponent,
    AsideNavComponent,
    StepLanguageComponent,
    StepActionComponent,
    StepHeaderActionComponent,
    SetTimeOpeningComponent,
    ImagePickerComponent,
    HeaderSearchComponent,
    BranchPackageComponent,
    PackagePeriodComponent,
    LandingPlaceComponent
  ]
})
export class LayoutModule {
}
