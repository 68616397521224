import { Component, OnInit } from '@angular/core';
import { PlaceService } from 'src/app/services/place.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { Place } from '../place.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  item: Place;

  constructor(
    private placeService: PlaceService,
    private _swl: SwalService,
    private _router: Router,
    private _data: DataService
  ) {}

  ngOnInit() {
    this.item = this._data.getData();

    if (!this.item) {
      this._router.navigate(['/setting/place']);
      return;
    }

    this.getList(this.item);
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value);
    });
  }

  updateItem(item) {
    item.id = this.item.id;
    this.placeService.putPlace(item).then(
      (result: any) => {
        if (item.image) {
          result.image = item.image;
          this.placeService.updatePlaceImage(result).then(result => {
            this._swl.swlOnSucess();
            this._router.navigate(['/setting/place']);
          });
        } else {
          this._swl.swlOnSucess();
          this._router.navigate(['/setting/place']);
        }
      },
      errors => {
        this._swl.swlOnError();
      }
    );
  }

  getList(item) {
    this.placeService.getPlace(item.id, { with_object: true }).then((result: Place) => {
      this.item = result;
    });
  }
}
