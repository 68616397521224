import { Component, OnInit } from '@angular/core';
import { PlaceType } from '../place-type.model';
import { PlaceTypeService } from 'src/app/services/place-type.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  form: any

  constructor(
    private placeTypeService: PlaceTypeService,
    private _swl: SwalService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.createItem(value)
    })
  }

  createItem(item: PlaceType) {
    this.placeTypeService.postPlaceType(item).then((result) => {
      this._swl.swlOnSucess()
      this._router.navigate(['/setting/place-type']);
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
