import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from './helpers';
import * as $ from 'jquery';
import { MainService } from './services/main.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  // globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

  constructor(private _router: Router, private _main: MainService) {}

  subscription: Subscription;

  ngOnInit() {
    this.subscription = this._router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this._main.scrollToTop();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    if (window.pageYOffset >= 300) {
      $('body').addClass('m-header--minimize-on');
      $('body').removeClass('m-header--minimize-off');
    } else {
      $('body').removeClass('m-header--minimize-on');
      $('body').addClass('m-header--minimize-off');
    }
  }
}
