import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/services/swal.service';
import { AboutService } from 'src/app/services/about.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  item$ = new BehaviorSubject<any>({})

  constructor(
    private _service: AboutService,
    private _swl: SwalService,
  ) { }

  ngOnInit() {
    this.getItem()
  }

  onSubmit(value) {
    this._swl.swlOnUpdate().then(() => {
      this.updateItem(value)
    })
  }

  updateItem(item) {
    item.id = this.item$.getValue().id
    this._service.putAbout(item).then((result) => {
      this._swl.swlOnSucess()
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

  getItem() {
    let filters = {
      with_object: true
    }
    this._service.getAbouts(filters).then((result: any) => {
      this.item$.next(result)
    })
  }

}
