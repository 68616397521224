import { Component, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { SwalService } from 'src/app/services/swal.service';
import { Router } from '@angular/router';
import { config } from 'src/app/services/config';
import { Subject } from 'rxjs';
import { FactService } from 'src/app/services/fact.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  items: Array<any> = []
  searchChanged: Subject<string> = new Subject<string>();
  config: any = {
    limit: 10,
    offset: 0,
    busy: false,
  }
  lang = config.DEFAULT_LANG

  constructor(
    private _service: FactService,
    private _data: DataService,
    private _swl: SwalService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.getItems()

    this.searchChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.config.keywords = data
        this.load()
      });
  }

  ngOnDestroy(): void {
    this.searchChanged.unsubscribe()
  }

  onSearch(text: string) {
    this.searchChanged.next(text);
  }

  onEdit(item) {
    this._data.setData(item)
    this._router.navigate(['setting/fact/update']);
  }
  
  onDelete(item) {
    this._swl.swlOnDelete().then(() => {
      this.deleteItem(item)
    })
  }

  load() {
    this.config.busy = false
    this.config.offset = 0
    this.items = []
    this.getItems()
  }

  getItems(): void {
    if (this.config.busy)
      return
    this.config.busy = true

    let filters = {
      limit: this.config.limit,
      offset: this.config.offset,
      keywords: this.config.keywords || '',
      lang: this.lang,
      with_object: true
    }
    this._service.getList(filters).then((results: Array<any>) => {
      if (results.length != 0) {
        this.items = this.items.concat(results)

        this.config.offset += this.config.limit

        this.config.busy = false
      }else {
        this.config.busy = true
      }
      
    })
  }

  deleteItem(item): void {
    this._service.delete(item).then((result) => {
      this._swl.swlOnSucess()
      this.load()
    }, (errors) => {
      this._swl.swlOnError()
    })
  }

}
