import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor() { }

  scrollToTop() {
    return $([document.documentElement, document.body]).animate({
      scrollTop: 0
    }, 700);
  }
}
