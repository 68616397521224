import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {

  @Output() imgURL = new EventEmitter<any>();
  @Output() image = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    var reader = new FileReader();
    this.image.emit(file);
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imgURL.emit(reader.result);
    }
  }

}
