import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Language, config } from 'src/app/services/config';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-step-action',
  templateUrl: './step-action.component.html',
  styleUrls: ['./step-action.component.scss']
})
export class StepActionComponent implements OnInit {
  @Input() currentStep: number;
  @Output() chagngeCurrentStep = new EventEmitter<number>();
  @Output() submitted = new EventEmitter<number>();

  languages: Array<Language> = config.LANGUAGES;

  constructor(private _main: MainService) {}

  ngOnInit() {
    // this._main.scrollToTop()
  }

  onSubmit() {
    this.submitted.emit();
  }

  onContinue() {
    this.currentStep++;
    this.chagngeCurrentStep.emit(this.currentStep);
    this._main.scrollToTop();
  }

  onBack() {
    this.currentStep--;
    this.chagngeCurrentStep.emit(this.currentStep);
    this._main.scrollToTop();
  }
}
